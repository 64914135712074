export const environment = {
    production: true,
    API_URL: 'https://admin.paco.app/api',
    ONE_SIGNAL_APP_ID: 'ae1334d9-6e6a-43af-bc51-e4e146d2f5d4',
    FIREBASE_SENDER_ID: 'FIREBASE_SENDER_ID',
    MAPSKEY: 'pk.eyJ1IjoiamN2aXpjYWlubyIsImEiOiJja2E4Zml1NHowZTkzMnJwaWdiMHlsMjZtIn0.6ciM4BUEDiwrrJi6xqlnPg',
    SMARTLOOK_KEY: '4a0168938f55324aa5d5d67e7ad826f6066894f7',
    HELP_URL:'https://ayuda.paco.app/portal/es/kb/soporte-t%C3%A9cnico/aplicaci%C3%B3n-m%C3%B3vil',
    API_KEY_ZOHO_ANDROID:'NDX2fFtcVzvwJqSqKLrRlPQxkrQEPiweEDFHVIPljF8tFnfUdhJeexPCANC9LnQa',
    API_KEY_ZOHO_IOS:"NDX2fFtcVzu56WjsWuSlUlFymsPDyTxNJKK%2FrOecJM5xVH5r4RHLs%2BpevCX0uYNt",
    ACCESS_TOKEN_ZOHO_ANDROID:'harFESiTd1208wZo0mqIt1QM9hoNtlpwXYyh%2FMs3fDeac1SyRu%2BlXmQx%2Bp4zYbiqazU1XiUde8GWUABGJGF3z0eQNfNFL16rABao9cSwuBECuXMIhQmnZivxmyPvqbWJ2FZwsOf1Glo3UKxN6WKmog%3D%3D',
    ACCESS_TOKEN_ZOHO_IOS:'1KSD2kf8j%2BJaD%2BBAiwP%2B4XPqDMB69C5owwrXkviNH9WpnlbPBQlwDGciZuWBM2Fb054SPOZylLvbqu4hcaCkfJgUC6la19BCqQuOV4S2XUw%3D',
    XML_BASE_URL:'https://admin.paco.app',
   BUGSNAG_API_KEY:'8bbb698c6e080ffebf89835df2d45f31',
    APP_ID_IOS:'1526706014',
    APP_GENERAL_NAME:'Paco'
};
